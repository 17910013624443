import React from 'react'
import { useForm} from 'react-hook-form'
import Layout from '../components/indexLayout'

import logoROHSGif from '../images/firstbond/Logo_ROHS.gif'

const ContactPage = () => {

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(data)
    };

    const url = '/api/sendMail'; 
    fetch(url, requestOptions)
      .then(response => {
                          document.getElementById("message-sent").style.display = "block";
                          document.getElementById("contact-form").style.display = "none";
                        })
      .catch(data =>    {
                          document.getElementById("message-error").style.display = "block";
                          document.getElementById("contact-form").style.display = "none";
                        });

  };

  return(
  <Layout>
    <div className="page white-bg">
      <div className="container">
        
        <section className="hero-section">
        
          <section className="hero-texts">

            <p className="firstbond-header">
              Devis Gratuit - FIRSTBOND France
            </p>

            <div id="contact-form" className="contact-form-box">

              <form className="card_form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-input">
                  <label className="form-input__label" for="prenom">Prénom</label>
                  <input type="text" className="form-input__input" {...register("prenom")} />
                </div>
                <div className="form-input">
                  <label className="form-input__label" for="nom">Nom</label>
                  <input type="text" className="form-input__input" {...register("nom")} />
                </div>
                <div className="form-input">
                  <label className="form-input__label" for="societe">Société</label>
                  <input type="text" className="form-input__input" {...register("societe")} />
                </div>
                <div className="form-input">
                  <label className="form-input__label" for="adresse">Adresse</label>
                  <input type="text" className="form-input__input" {...register("adresse")} />
                </div>
                <div className="form-input">
                  <label className="form-input__label" for="codepostal">Code postal</label>
                  <input type="text" className="form-input__input" {...register("codepostal")} />
                </div>
                <div className="form-input">
                  <label className="form-input__label" for="ville">Ville</label>
                  <input type="text" className="form-input__input" {...register("ville")} />
                </div>
                <div className="form-input">
                  <label className="form-input__label" for="telephone">Téléphone</label>
                  <input type="text" className="form-input__input" {...register("telephone")} />
                </div>
                <div className="form-input">
                  <label className="form-input__label" for="fax">Fax</label>
                  <input type="text" className="form-input__input" {...register("fax")} />
                </div>
                <div className="form-input">
                  <label className="form-input__label" for="email">Email</label>
                  <input type="text" className="form-input__input" {...register("email")} />
                </div>
                {/*
                <div>
                  Merci d'indiquer ici la quantité de panneaux et le coloris, en cas de découpe une liste avec les cotes (pas de plan). Nous vous répondons dans les 24h.
                </div>
                */}

                <p>&nbsp;</p>
                <div className="form-input">
                  <label className="form-input__label" for="message">Merci d'indiquer ici la quantité de panneaux et le coloris, en cas de découpe une liste avec les cotes (pas de plan). Nous vous répondons dans les 24h.</label>
                  <textarea className="form-input__input" {...register("message")}></textarea>
                </div>

                {/*<button type="submit">Envoyer</button>*/}
                {/*<input type="submit" value="Envoyer" />*/}

                <div className="form-button">
                  <button className="form-button__button" type="submit">Envoyer</button>
                </div>

              </form>
        
            </div>

            <div id="message-sent">
              Merci de nous avoir contacté. Nous vous répondrons dans les 24h.
            </div>
            <div id="message-error">
              Une erreur est survenue lors de l'envoi de votre message. Veuillez ré-essayer.
            </div>

          </section>

        </section>


        <section className="hero-section">
          <div className="info-area">
            <div className="logo-rohs"><img src={logoROHSGif} alt="ROHS" width="121" height="65"/></div>
            <div className="mention-rohs">
              Tous nos produits sont certifiés CE et à la norme RoHS.
            </div>
          </div>
        </section>

      </div>

    </div>

  </Layout>
  )
  }

export default ContactPage
